import { computed } from 'vue'
import { RouteLocation, useRoute } from 'vue-router'
import { getStaticUserData } from '@/api/utils/user'

/**
 * Check if the user is on subsidiary view
 * @param route current route or the route we're navigating to
 * @description use it only when You can't use {@link useCurrentUser}
 * @returns `true` when the user is on subsidiary company route
 */
export const checkIfSubsidiary = ({ params }: RouteLocation) => {
  const { companyId: currentCompanyId } = getStaticUserData()
  const { companyId } = params
  if (!companyId) {
    return false
  }

  return currentCompanyId !== Number(companyId)
}

/**
 * Check current company id based on route's `companyId` param with fallback to `currentUser.companyId`
 * @param route current route or the route we're navigating to
 * @description use it only when You can't use {@link useCurrentUser}
 * @returns current companyId
 */
export const getCompanyId = ({ params: { companyId } }: RouteLocation) => {
  const { companyId: currentCompanyId } = getStaticUserData()
  if (Array.isArray(companyId)) {
    throw new Error("companyId can't be an array")
  }
  if (companyId === undefined || companyId === '') {
    return Number(currentCompanyId)
  }

  return Number(companyId)
}

export const useCurrentCompany = () => {
  const route = useRoute()

  return {
    /**
     * Current company id based on route's `companyId` param with fallback to `currentUser.companyId`
     */
    currentCompanyId: computed(() => getCompanyId(route)),
    /**
     * Check if the user is checking subsidiary company
     * @description Is `true` when route's `companyId` param is set and different from `currentUser.companyId`
     */
    isSubsidiary: computed<boolean | undefined>(() => checkIfSubsidiary(route)),
  }
}
