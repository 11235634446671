import { Language, LanguageCode } from '@/api/useLanguages.types'

export const getStaticUserData = () => {
  const user = window.App?.HELPERS.currentUser

  return {
    companyId: user?.companyId,
    userId: user?.id,
    language: user?.language ?? Language.English,
    languageCode: user?.language
      ? (`${user?.language}-DE` as LanguageCode)
      : LanguageCode.English,
  }
}
